import React, { Component } from 'react';

class AccordionItem extends Component {
  state = {
    opened: false
  }

  abrir_modulo = (id_opcion,opcion) =>{
    this.props.getOpcion(id_opcion,opcion);
  }
  render () {
    const {
      props: {
        categoria,
        opcion
      },
      state: {
        opened
      }
    } = this


    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`
        }}
      >
        <div {...{ className: 'accordion-item__line' ,
        onClick: () => { this.setState({ opened: !opened }) }}}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {categoria}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              {opcion.map((ditem , dindex) => (
                <div className="div_opcion" key={dindex}  onClick={ () => this.abrir_modulo(ditem.id,ditem.component)}>
                  <p {...{ className: 'accordion-item__paragraph' }}>
                    {ditem.component}
                  </p>
                </div>
              ))}
            </div>
          </div>
      </div>
    )
  }
}
export default AccordionItem;

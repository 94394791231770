import React, { Component } from 'react';
import StickyHeadTable from './../Componentes/StickyHeadTable';
import 'moment/locale/es.js';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import './../css/getBusqueda.css';
class getExportarInformacion extends Component {
  constructor(){
    super();
    this.state = {
      rows_actual : [],
      rows : [],
      id_activo : 0,
      ficha_activo :[],
      ficha_activo_catalogo: [],
      id_estado : 0,
      id_operativo : 0,
      id_ubicacion_1 : 0,
      id_ubicacion_2 : 0,
      id_ubicacion_3 : 0,
      date :'',
      codigo :'',
      id_cuenta: 0
    }
//`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }

  hC_Campo16 = (event) => {
		this.setState({id_estado : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }
  hC_Campo17 = (event) => {
		this.setState({id_operativo : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }
  hC_Campo18 = (event) => {
		this.setState({id_ubicacion_1 : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }
  hC_Campo19 = (event) => {
		this.setState({id_ubicacion_2 : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }
  hC_Campo20 = (event) => {
		this.setState({id_ubicacion_3 : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }
  hC_Campo21 = (event) => {
		this.setState({id_cuenta : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }

  handleChange = (event) => {
		this.setState({codigo : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }
  getBusqueda_ = () =>{
    let rows_actual = this.state.rows_actual;
    let id_estado = Number(this.state.id_estado);
    if(id_estado>0){
      rows_actual = rows_actual.filter(item => Number(item.id_estado) === Number(id_estado));
    }

    let id_operativo = Number(this.state.id_operativo);
    if(id_operativo>0){
      rows_actual = rows_actual.filter(item => Number(item.id_operativo) === Number(id_operativo));
    }

    let id_ubicacion_1 = Number(this.state.id_ubicacion_1);
    if(id_ubicacion_1>0){
      rows_actual = rows_actual.filter(item => Number(item.id_ubicacion_1) === Number(id_ubicacion_1));
    }

    let id_ubicacion_2 = Number(this.state.id_ubicacion_2);
    if(id_ubicacion_2>0){
      rows_actual = rows_actual.filter(item => Number(item.id_ubicacion_2) === Number(id_ubicacion_2));
    }

    let id_ubicacion_3 = Number(this.state.id_ubicacion_3);
    if(id_ubicacion_3>0){
      rows_actual = rows_actual.filter(item => Number(item.id_ubicacion_3) === Number(id_ubicacion_3));
    }
    let id_cuenta = Number(this.state.id_cuenta);
    if(id_cuenta>0){
      rows_actual = rows_actual.filter(item => Number(item.id_cuenta) === Number(id_cuenta));
    }

    let codigo = this.state.codigo;
    //console.log(codigo.substr(0, 2))
    if(codigo!==''){
      rows_actual = rows_actual.filter(item => item.codigo.substr(0, codigo.length) === codigo);
    }
    this.setState({
      rows : rows_actual
    })
    //const list_array_nota = this.state.array_nota.filter(item => item.secuencial !== secuencial);

  }



  consultarAPI = () => {


    const url= this.props.url_global + "webservices/getListaActivos/";
    // console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ rows_actual: data,rows: data })
        }
      })
      .catch(console.log)
  }
  consultarFichaAPI = (id) => {

  }
  consultarFiltosAPI = () => {
    const url= this.props.url_global + "webservices/getCatalogosActivos/";
     //console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ ficha_activo_catalogo: data })
        }
      })
      .catch(console.log)

  }

  setOpenFicha =(id)=>{
  //  this.consultarFichaAPI(id);
  }
  getActualizarFicha =(valor)=>{

  }
  onChange = (jsDate, dateString) => {
    // ...
  }

  exportar = () => {
    let id_item ="";
    const rows = this.state.rows;
    rows.forEach(cp => {
      id_item = id_item+","+cp.id_activo
    })
console.log(id_item)
    this.props.setLoading();
    window.open(this.props.url_global + "webservices/getExportarExcel", "_self","id_="+id_item)
    this.props.setLoadingClose();
  }

  render(){
    const ficha_activo_catalogo = this.state.ficha_activo_catalogo;
    let estado=
      <select className="select-css" style={{width: "150px", display: "inline"}} value={this.state.id_estado} onChange={(event)=>this.hC_Campo16(event)}>
        <option value="0">Todos</option>
        {(ficha_activo_catalogo.length !== 0) ?
          ficha_activo_catalogo.filter(item => item.id_tipo === '6').map(catalogo => (
            <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
          ))
          : <React.Fragment></React.Fragment>
        }
      </select>

      let operativo=
        <select className="select-css" style={{width: "150px", display: "inline"}} value={this.state.id_operativo} onChange={(event)=>this.hC_Campo17(event)}>
          <option value="0">Todos</option>
          {(ficha_activo_catalogo.length !== 0) ?
            ficha_activo_catalogo.filter(item => item.id_tipo === '7').map(catalogo => (
              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
            ))
            : <React.Fragment></React.Fragment>
          }
        </select>

        let ubicacion_1=
          <select className="select-css" style={{width: "200px", display: "inline"}} value={this.state.id_ubicacion_1} onChange={(event)=>this.hC_Campo18(event)}>
            <option value="0">Todos</option>
            {(ficha_activo_catalogo.length !== 0) ?
              ficha_activo_catalogo.filter(item => item.id_tipo === '8').map(catalogo => (
                <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
              ))
              : <React.Fragment></React.Fragment>
            }
          </select>

        let ubicacion_2=
          <select className="select-css" style={{width: "200px", display: "inline"}} value={this.state.id_ubicacion_2} onChange={(event)=>this.hC_Campo19(event)}>
            <option value="0">Todos</option>
            {(ficha_activo_catalogo.length !== 0) ?
              ficha_activo_catalogo.filter(item => item.id_tipo === '9').map(catalogo => (
                <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
              ))
              : <React.Fragment></React.Fragment>
            }
          </select>

          let ubicacion_3=
            <select className="select-css"  style={{width: "200px", display: "inline"}} value={this.state.id_ubicacion_3} onChange={(event)=>this.hC_Campo20(event)}>
              <option value="0">Todos</option>
              {(ficha_activo_catalogo.length !== 0) ?
                ficha_activo_catalogo.filter(item => item.id_tipo === '10').map(catalogo => (
                  <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
                ))
                : <React.Fragment></React.Fragment>
              }
            </select>
            let cuenta=
              <select className="select-css"  style={{width: "200px", display: "inline"}} value={this.state.id_cuenta} onChange={(event)=>this.hC_Campo21(event)}>
                <option value="0">Todos</option>
                {(ficha_activo_catalogo.length !== 0) ?
                  ficha_activo_catalogo.filter(item => item.id_tipo === '13').map(catalogo => (
                    <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
                  ))
                  : <React.Fragment></React.Fragment>
                }
              </select>

      return (
        <div className="grid-container-busqueda">
          <div className="lbl_filtros" >
             <table style={{'text-align': 'left','width':'100%'}}>
    				<tbody>
            <tr>
              <th className="lbl_color">Cuenta:</th>
              <th>{cuenta}</th>
             </tr>
    					<tr>
    						<th className="lbl_color">Estado:</th>
    						<th>{estado}</th>
                <th className="lbl_color">Operativo:</th>
    						<th>{operativo}</th>
    					 </tr>
               <tr>
     						<th className="lbl_color">Ubicación 1:</th>
     						<th>{ubicacion_1}</th>
                 <th className="lbl_color">Ubicación 2:</th>
     						<th>{ubicacion_2}</th>
     					 </tr>
               <tr>
     						<th className="lbl_color">Ubicación 3:</th>
     						<th>{ubicacion_3}</th>
                <th className="lbl_color">Código</th>
     						<th>  <input className="input_txt" type="text" name="codigo" value={this.state.codigo} onChange={this.handleChange} placeholder="Código" /></th>
     					 </tr>
               <tr>
     						<th className="lbl_color">Fecha Adquisición Desde:</th>
                <th><DatePickerInput style={{width: "150px"}} locale='es' onChange={this.onChange} value={this.state.date} className='my-custom-datepicker-component' /></th>
                <th className="lbl_color">Hasta: </th>
                <th>
                <DatePickerInput style={{width: "150px"}} locale='es' onChange={this.onChange} value={this.state.date} className='my-custom-datepicker-component' />
                </th>
     					 </tr>
               <tr>
                <th colSpan="4">
                  <button className="button_login" onClick={ () => this.exportar()}>Exportar a EXCEL</button>
                </th>
               </tr>

             </tbody>
            </table>
          </div>

          <div className="lbl_detalle_tabla lbl_color">
            Lista de Activos<br/>
            <StickyHeadTable setOpenFicha={this.setOpenFicha}  rows={this.state.rows} />
          </div>
        </div>
      )
  }
  componentDidMount (){
    this.consultarAPI();
    this.consultarFiltosAPI();
  }
}
export default getExportarInformacion;

import React, { Component } from 'react';
import StickyHeadTableCatalogoCC from './../Componentes/StickyHeadTableCatalogoCC';

class getCuentaContable extends Component {

  constructor(){
    super();
    this.state = {
      ficha_activo_catalogo : [],
      rows : [],
      accion: 'crear',
      item_catalogo: '',
      id_item_catalogo : 0,
      codigo:''
    }
  }

  consultarFiltosAPI = () => {
    const url= this.props.url_global + "webservices/getCatalogosActivosID/13";
    console.log(url)
     fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ rows : data })
        }
      })
      .catch(console.log)

  }
  handleChange = (event) => {
		this.setState({item_catalogo : event.target.value});
  }
  handleChangeC = (event) => {
		this.setState({codigo : event.target.value});
  }
  crear_cuenta_contable = () => {
    const item_catalogo = this.state.item_catalogo;
    const codigo = this.state.codigo;
    if(item_catalogo.trim()===''){
      alert("Ingrese Nombre Cuenta Contable")
      return
    }
    if(codigo.trim()===''){
      alert("Ingrese Código Cuenta Contable")
      return
    }
    const url=  this.props.url_global + "webservices/getCrearCatalogo";
    var obj = { id:13,nombre:item_catalogo,codigo};

    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(obj), // data can be `string` or {object}!
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log(data[0].status)
      if(data[0].status==="ok"){
        this.encerar();
      }
      this.props.setLoadingClose();
    })
    .catch(console.log)
    this.props.setLoading();
  }

  actualizar_cuenta_contable = () => {
    const item_catalogo = this.state.item_catalogo;
    const id_item_catalogo = this.state.id_item_catalogo;
    const codigo = this.state.codigo;
    if(item_catalogo.trim()===''){
      alert("Ingrese Nombre Cuenta Contable")
      return
    }
    if(codigo.trim()===''){
      alert("Ingrese Código Cuenta Contable")
      return
    }
    const url=  this.props.url_global + "webservices/getActualizarCatalogo";
    var obj = { id:13,nombre:item_catalogo,id_item_catalogo,codigo};

    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(obj), // data can be `string` or {object}!
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log(data[0].status)
      if(data[0].status==="ok"){
        this.encerar();
      }
      this.props.setLoadingClose();
    })
    .catch(console.log)
    this.props.setLoading();
  }


  encerar =()=>{
    this.setState({
        accion : "crear",
        item_catalogo : '',
        codigo:""
      }, () =>
      {
        this.consultarFiltosAPI()
      }
    )

  }

  setOpenFicha = (id,catalogo,codigo) =>{
    this.setState({
      accion : 'actualizar',
      id_item_catalogo: id,
      item_catalogo: catalogo,
      codigo
    })
  }
  render(){
      let modulo;
      if(this.state.accion==='crear'){
        modulo =<button className="button_login" onClick={ () => this.crear_cuenta_contable()}>Crear Cuenta Contable</button>
      }
      if(this.state.accion==='actualizar'){
        modulo = <React.Fragment>
                <button className="button_login" onClick={ () => this.actualizar_cuenta_contable()}>Actualizar Cuenta Contable</button>
                <button className="button_login" onClick={ () => this.encerar()}>Cancelar</button>
                </React.Fragment>
      }

      return (
        <div>
          <div>
            <table>
              <tr>
                <td className="lbl_color">
                  Nombre de Cuenta Contable:
                </td>
                <td>
                <input className="input_txt" type="text" name="item_catalogo" value={this.state.item_catalogo} onChange={this.handleChange} placeholder="Nombre de Cuenta Contable" />
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td className="lbl_color">
                  Código de Cuenta Contable:
                </td>
                <td>
                <input className="input_txt" type="text" name="codigo" value={this.state.codigo} onChange={this.handleChangeC} placeholder="Código de Cuenta Contable" />
                </td>
                <td>
                {modulo}
                </td>
              </tr>
            </table>
            Lista de Resposables<br/>
            <StickyHeadTableCatalogoCC setOpenFicha={this.setOpenFicha}  rows={this.state.rows} />
          </div>
        </div>
      )
  }
  componentDidMount (){
    this.consultarFiltosAPI();
  }
}
export default getCuentaContable;

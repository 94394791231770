import React, { Component } from 'react';
import Accordion from './../Componentes/Accordion';
import GetBusqueda from './../Componentes/getBusqueda';
import GetEntrega from './../Componentes/getEntrega';
import GetCrearActivo from './../Componentes/getCrearActivo';
import GetResponsables from './../Componentes/getResponsables';
import GetCuentaContable from './../Componentes/getCuentaContable';
import GetExportarInformacion from './../Componentes/getExportarInformacion';
import GetTransferenciaResponsable from './../Componentes/getTransferenciaResponsable';
import GetTransferenciaUbicacion from './../Componentes/getTransferenciaUbicacion';

class getHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modulo_activo :'Búsqueda'/* 'Búsqueda'*/ ,
      id_filtro : '0',
      items_pedidos : [],
      cantidad_carrito : 0 ,
      clientes : [] ,
      id_cliente : 0 ,
      id_item_activo : 0,
      sms_mensaje :'',
      nom_cliente :'',
      data : [
        {
          "categoria":"Activos",
          "opcion":
            [
              {"id": "1","component":"Búsqueda"},
              {"id": "1","component":"Crear Activo"}
           ]
       },
       {
         "categoria":"Actas",
         "opcion":
          [
            {"id": "2","component":"Entrega"},
            {"id": "3","component":"Baja de Activos"},
            {"id": "4","component":"Transferencia de Responsable"},
            {"id": "5","component":"Transferencia de Ubicación"},
            /*{"id": "6","component":"Levantamiento de Información"},*/
            {"id": "7","component":"Revalorización de Activos"}
          ]
       },
       {
          "categoria":"Reportes",
          "opcion":
          [
          /*  {"id": "8","component":"Por Filtros"},*/
            {"id": "9","component":"Depreciación"},
            {"id": "10","component":"Depreciación Consolidada"},
            {"id": "11","component":"Imágenes"},
            {"id": "17","component":"Actas"}
          ]
        },
        {
          "categoria":"Configuración Avanzada",
          "opcion":
          [
            {"id": "12","component":"Exportar información"},
            /*{"id": "13","component":"Exportar información por Formato"},*/
            /*{"id": "14","component":"Importar información"},*/
            {"id": "15","component":"Parametrización de Cuentas Contable"},
            {"id": "16","component":"Crear Responsables"}
          ]
        }
      ]
    }
  }

  getHomeModule = () => {
   this.setState({
     modulo_activo: 'home'
   })
 }





  getExitModule = () =>{
    this.setState({
      modulo_activo: 'home'
    } ,  () =>
      {
       this.props.salir();
      }
    )
  }


  getOpcion = (id_opcion,opcion) =>{
    //  this.props.getOpcion(id_opcion,opcion);
      this.setState({
        modulo_activo:opcion
      })

  }


  getFinalizar = () =>{
    const items = this.state.items_pedidos;
    let modulo_activo= this.state.modulo_activo;
    if(modulo_activo==='finalizar'||modulo_activo==='home'){
      modulo_activo='new';
    }else{
      if(items.length === 0) return null;
      modulo_activo='finalizar';
    }
    this.setState({
      modulo_activo
    })
  }


  render() {
    const modulo_activo =this.state.modulo_activo;
    let modulo;
    if(modulo_activo==='Búsqueda'){
      modulo = <GetBusqueda url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }
    if(modulo_activo==='Crear Activo'){
      modulo = <GetCrearActivo url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }
    if(modulo_activo==='Entrega'){
      modulo = <GetEntrega url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }

    if(modulo_activo==='Transferencia de Responsable'){
      modulo = <GetTransferenciaResponsable url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }
    if(modulo_activo==='Transferencia de Ubicación'){
      modulo = <GetTransferenciaUbicacion url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }
    if(modulo_activo==='Imágenes'){
      window.open(this.props.url_global + "webservices/getImages", "_blank")
    }

    if(modulo_activo==='Crear Responsables'){
      modulo = <GetResponsables url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }
    if(modulo_activo==='Parametrización de Cuentas Contable'){
      modulo = <GetCuentaContable url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }
    if(modulo_activo==='Exportar información'){
      modulo = <GetExportarInformacion url_global={this.props.url_global} setLoading={this.props.setLoading} setLoadingClose={this.props.setLoadingClose} />;
    }


    return (
      <div className="grid-container-activos">
      <div className="lbl_cabecera"><br/>Bienvenido/a al Sistema de Control de Inventarios
      <img alt="Cargando Imagen . . ." onClick={ () => this.props.salir()} className="lbl_loading" src={ require('../images/salir.png') } />
      <br/>
      {"*** "+this.state.modulo_activo+"  ***"}
      </div>
      <div className="lbl_menu">
        <Accordion getOpcion={this.getOpcion} data={this.state.data} />
      </div>
      <div className="lbl_contenido">
        {modulo}
      </div>
    </div>
    );
  }

  componentDidMount (){
  }


}
export default getHome;

import React, {Component} from 'react';
//import logo from './logo.svg';
import './App.css';
import GetLogin from './Componentes/getLogin';
import GetHome from './Componentes/getHome';
//import GetConfiguracion from './Componentes/getConfiguracion';
//import './css/getConfiguracion.css';
import './css/accordion.scss';
import './css/getFichaActivo.css';
//import './css/getListaPedidos.css';
//import './css/getDetallePedido.css';

class App extends Component {

  state = {

    id_usuario : '0',
    sw_usuario : '' ,
    name : 'Sistema de Activos On-Line Neola v1' ,
    url_global : "http://209.182.217.19/activos/index.php/",
    usuario : '',
    id_vendedor : 0,
    sms_mensaje : '',
    nombre_maquina : '',
    sw_grabado : '',
    cajas : [],
    id_caja: 0,
    loading :false
  }
  //http://localhost/neola/index.php/


  setLoading = () => {
    this.setState({
      loading:true
    })
  }

  setLoadingClose = () => {
    this.setState({
      loading:false
    })
  }


  salir = () => {
    //localStorage.clear();
    localStorage.id_usuario = 0;
    this.setState(
      {
        sw_usuario : '' , id_usuario : '0'
      }
    )
    /*, () =>
    {
      localStorage.clear();
    }*/
  }

  setMaquina = (nombre_maquina) => {
    this.setState(
      {
        nombre_maquina
      }
    )
  }

  setIdCaja = (id_caja) => {
    this.setState(
      {
        id_caja
      }
    )
  }


  setURL = (url_global) => {
    this.setState(
      {
        url_global
      }, () => {
        this.getCajaAPI();
      }
    )
  }
  setConfiguracion = () => {
    localStorage.nombre_maquina = this.state.nombre_maquina;
    localStorage.url_global = this.state.url_global;
    localStorage.sw_grabado = 'SI';
    this.getConfiguracionAPI();
    this.setState(
      {
        sw_grabado : 'SI'
      }
    )
  }
  getCajaAPI = () => {
    const url= this.state.url_global + "webservices/getCajas/";
      fetch( url)
      .then(res => res.json())
      .then((data) => {
        this.setState({ cajas: data })
      })
      .catch(console.log)
  }

  getConfiguracionAPI = () => {
    const url=  this.state.url_global + "webservices/getConfiguracion/"+this.state.nombre_maquina+"/"+this.state.id_caja;
    //console.log(url);
    fetch(url)
      .catch(console.log)
  }

  consultarUsuarioAPI = (usuario,clave) => {
    const url= this.state.url_global + "webservices/PgetLoginVendedor/"+usuario+"/"+clave;
    fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].id_usuario){
          if(Number(data[0].id_usuario)>0){
            localStorage.id_usuario = data[0].id_usuario;
            localStorage.sw_usuario = 'vendedor';
            localStorage.usuario = data[0].usuario;
            localStorage.id_vendedor = data[0].id_personal;
            this.setState({id_usuario : data[0].id_usuario, usuario : data[0].usuario, id_vendedor : data[0].id_personal })
          }else{
            this.setState({
              sms_mensaje : 'Usuario o Contraseña Incorrectas'
            })
          }
        }
      })
      .catch(console.log)
  }

  BuscarUsuario = (usuario , clave) => {

    if(usuario===''){
       alert('Ingrese Usuario');
         return null;
    }
     if(clave===''){
      alert('Ingrese Clave');
        return null;
    }
    this.consultarUsuarioAPI(usuario , clave);
 }

  render() {
    let loading;
    if(this.state.loading){
      loading =<div id="loader" className="loader loader-default is-active" data-text=""></div>
    }

    const id_usuario  = this.state.id_usuario;
    let modulo;
    if (id_usuario==='0') {
      modulo = <GetLogin sms_mensaje={this.state.sms_mensaje} BuscarUsuario={this.BuscarUsuario} name={this.state.name}/>;
    } else {
      modulo = <GetHome nombre_maquina={this.state.nombre_maquina} setLoadingClose={this.setLoadingClose} setLoading={this.setLoading} usuario={this.state.usuario} id_vendedor={this.state.id_vendedor} salir={this.salir} url_global={this.state.url_global} id_usuario={this.state.id_usuario} />;
    }

    return (
      <div className="App">
        {loading}
        {modulo}
      </div>
    );
  }

  componentDidMount() {
    //localStorage.sw_grabado="";

    if(localStorage.id_usuario){
      this.setState(
        {
          id_usuario : localStorage.id_usuario,
          sw_usuario : localStorage.sw_usuario,
          usuario : localStorage.usuario,
          id_vendedor : localStorage.id_vendedor,
        }
      )
    //  return null;
  }

    //this.consultarRubroAPI();
  }

}

export default App;

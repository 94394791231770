import React, { Component } from 'react';
import StickyHeadTableCatalogo from './../Componentes/StickyHeadTableCatalogo';

class getResponsables extends Component {

  constructor(){
    super();
    this.state = {
      ficha_activo_catalogo : [],
      rows : [],
      accion: 'crear',
      item_catalogo: '',
      id_item_catalogo : 0,
      codigo :'',
      cargo : '',
      dependencia : ''
    }
  }

  consultarFiltosAPI = () => {
    const url= this.props.url_global + "webservices/getCatalogosActivosID/11";

     fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ rows : data })
        }
      })
      .catch(console.log)

  }
  handleChange = (event) => {
		this.setState({item_catalogo : event.target.value});
  }
  handleChangeCod = (event) => {
		this.setState({codigo : event.target.value});
  }
  handleChangeCar = (event) => {
		this.setState({cargo : event.target.value});
  }
  handleChangeDep = (event) => {
		this.setState({dependencia : event.target.value});
  }
  crear_responsable = () => {
    const item_catalogo = this.state.item_catalogo;
    const codigo = this.state.codigo;
    const cargo = this.state.cargo;
    const dependencia = this.state.dependencia;

    if(item_catalogo.trim()===''){
      alert("Ingrese Nombre Responsable")
      return
    }
    const url=  this.props.url_global + "webservices/getCrearCatalogo";
    var obj = { id:11,nombre:item_catalogo,codigo,cargo,dependencia};

    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(obj), // data can be `string` or {object}!
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log(data[0].status)
      if(data[0].status==="ok"){
        this.encerar();
      }
      this.props.setLoadingClose();
    })
    .catch(console.log)
    this.props.setLoading();
  }

  actualizar_responsable = () => {
    const item_catalogo = this.state.item_catalogo;
    const id_item_catalogo = this.state.id_item_catalogo;
    const codigo = this.state.codigo;
    const cargo = this.state.cargo;
    const dependencia = this.state.dependencia;
    if(item_catalogo.trim()===''){
      alert("Ingrese Nombre Responsable")
      return
    }
    const url=  this.props.url_global + "webservices/getActualizarCatalogo";
    var obj = { id:11,nombre:item_catalogo,id_item_catalogo,codigo,cargo,dependencia};

    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(obj), // data can be `string` or {object}!
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then((data) => {
      console.log(data[0].status)
      if(data[0].status==="ok"){
        this.encerar();
      }
      this.props.setLoadingClose();
    })
    .catch(console.log)
    this.props.setLoading();
  }


  encerar =()=>{
    this.setState({
        accion : "crear",
        item_catalogo : '',
        codigo:'',
        cargo:'',
        dependencia:''
      }, () =>
      {
        this.consultarFiltosAPI()
      }
    )

  }

  setOpenFicha = (id,catalogo,codigo,cargo,dependencia) =>{
    this.setState({
      accion : 'actualizar',
      id_item_catalogo: id,
      item_catalogo: catalogo,
      codigo,cargo,dependencia
    })
  }
  render(){
      let modulo;
      if(this.state.accion==='crear'){
        modulo =<button className="button_login" onClick={ () => this.crear_responsable()}>Crear Responsable</button>
      }
      if(this.state.accion==='actualizar'){
        modulo = <React.Fragment>
                <button className="button_login" onClick={ () => this.actualizar_responsable()}>Actualizar Responsable</button>
                <button className="button_login" onClick={ () => this.encerar()}>Cancelar</button>
                </React.Fragment>
      }

      return (
        <div>
          <div>
            <table>
              <tr>
                <td className="lbl_color">
                  Nombre de Responsable:
                </td>
                <td>
                <input className="input_txt" type="text" name="item_catalogo" value={this.state.item_catalogo} onChange={this.handleChange} placeholder="Nombre de Responsable" />
                </td>
                <td rowspan="4">
                {modulo}
                </td>
              </tr>
              <tr>
                <td className="lbl_color">
                  Código:
                </td>
                <td>
                <input className="input_txt" type="text" name="codigo" value={this.state.codigo} onChange={this.handleChangeCod} placeholder="Código de Responsable" />
                </td>
              </tr>
              <tr>
                <td className="lbl_color">
                  Cargo:
                </td>
                <td>
                <input className="input_txt" type="text" name="cargo" value={this.state.cargo} onChange={this.handleChangeCar} placeholder="Cargo de Responsable" />
                </td>
              </tr>
              <tr>
                <td className="lbl_color">
                  Dependencia:
                </td>
                <td>
                <input className="input_txt" type="text" name="dependencia" value={this.state.dependencia} onChange={this.handleChangeDep} placeholder="Dependencia de Responsable" />
                </td>
              </tr>
            </table>
            Lista de Resposables<br/>
            <StickyHeadTableCatalogo setOpenFicha={this.setOpenFicha}  rows={this.state.rows} />
          </div>
        </div>
      )
  }
  componentDidMount (){
    this.consultarFiltosAPI();
  }
}
export default getResponsables;

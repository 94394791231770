/*CODIGO	CUENTA	DESCRIPCION	CARACTERISTICAS	MARCA	MODELO
SERIE	LARGO
	ANCHO	ALTO	DIAMETRO
	COLOR	MATERIAL 1	MATERIAL 2	ESTADO	"OPER
ATIVO"	UBICACIÓN1	UBICACIÓN2	UBICACIÓN3	"VALOR
ACTUAL"
select id_item_activo_ficha, cuenta_contable,  descripcion, caracteristicas, c1.item_catalogo as marca,c2.item_catalogo as modelo,
serie, largo, ancho, diametro, c3.item_catalogo as color,c4.item_catalogo as  material_1,c5.item_catalogo as  material_2,
 c6.item_catalogo as operativo, c7.item_catalogo as ubicacion_1,c8.item_catalogo as  ubicacion_2,
 c9.item_catalogo as ubicacion_3, valor,
*/
import React, { Component } from 'react';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
//import ReactImageAppear from 'react-image-appear';
class getFichaActivo extends Component {

	constructor(){
    super();
    this.state = {
      date : ''
    }

  }


	hC_Campo1 = (event) => {
		this.props.ficha_activo[0].codigo = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo2 = (event) => {
		this.props.ficha_activo[0].cuenta_contable = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo3 = (event) => {
		this.props.ficha_activo[0].descripcion = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo4 = (event) => {
		this.props.ficha_activo[0].caracteristicas = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }

	hC_Campo5 = (event) => {
		this.props.ficha_activo[0].serie = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo6 = (event) => {
		this.props.ficha_activo[0].largo = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo7 = (event) => {
		this.props.ficha_activo[0].ancho = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo8 = (event) => {
		this.props.ficha_activo[0].alto = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo9 = (event) => {
		this.props.ficha_activo[0].diametro = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo10 = (event) => {
		this.props.ficha_activo[0].valor = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo11 = (event) => {
		this.props.ficha_activo[0].id_marca = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }

	hC_Campo12 = (event) => {
		this.props.ficha_activo[0].id_modelo = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo13 = (event) => {
		this.props.ficha_activo[0].id_color = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo14 = (event) => {
		this.props.ficha_activo[0].id_material_1 = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo15 = (event) => {
		this.props.ficha_activo[0].id_material_2 = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }

  hC_Campo16 = (event) => {
		this.props.ficha_activo[0].id_estado = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
  hC_Campo17 = (event) => {
		this.props.ficha_activo[0].id_operativo = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
  hC_Campo18 = (event) => {
		this.props.ficha_activo[0].id_ubicacion_1 = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
  hC_Campo19 = (event) => {
		this.props.ficha_activo[0].id_ubicacion_2 = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
  hC_Campo20 = (event) => {
		this.props.ficha_activo[0].id_ubicacion_3 = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo21 = (event) => {
		this.props.ficha_activo[0].id_responsable = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }
	hC_Campo22 = (event) => {
		this.props.ficha_activo[0].id_control = event.target.value;
    this.props.getActualizarFicha(event.target.value);
  }

	hC_Campo23 = (event) => {
		this.props.ficha_activo[0].vida_util = event.target.value;
		this.props.getActualizarFicha(event.target.value);
	}
	hC_Campo24 = (event) => {
		this.props.ficha_activo[0].numero_factura = event.target.value;
		this.props.getActualizarFicha(event.target.value);
	}
	hC_Campo25 = (event) => {
		this.props.ficha_activo[0].proveedor = event.target.value;
		this.props.getActualizarFicha(event.target.value);
	}
	hC_Campo26 = (event) => {
		this.props.ficha_activo[0].numero_documento = event.target.value;
		this.props.getActualizarFicha(event.target.value);
	}

	onChange = (jsDate, dateString) => {
    // ...
  }

  render(){
		  let ficha_activo;
			//console.log(this.props.ficha_activo.length)



			if(this.props.ficha_activo.length===0){
				ficha_activo =<React.Fragment></React.Fragment>
			}else{
				const ficha_activo_catalogo = this.props.ficha_activo_catalogo;
				//console.log(ficha_activo_catalogo)
				let marca=
				  <select className="select-css" value={this.props.ficha_activo[0].id_marca} onChange={(event)=>this.hC_Campo11(event)}>
	          {(ficha_activo_catalogo.length !== 0) ?
	            ficha_activo_catalogo.filter(item => item.id_tipo === '1').map(catalogo => (
	              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
	            ))
	            : <React.Fragment></React.Fragment>
	          }
	        </select>

					let modelo=
					  <select className="select-css" value={this.props.ficha_activo[0].id_modelo} onChange={(event)=>this.hC_Campo12(event)}>
		          {(ficha_activo_catalogo.length !== 0) ?
		            ficha_activo_catalogo.filter(item => item.id_tipo === '2').map(catalogo => (
		              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
		            ))
		            : <React.Fragment></React.Fragment>
		          }
		        </select>

						let color=
						  <select className="select-css" value={this.props.ficha_activo[0].id_color} onChange={(event)=>this.hC_Campo13(event)}>
			          {(ficha_activo_catalogo.length !== 0) ?
			            ficha_activo_catalogo.filter(item => item.id_tipo === '3').map(catalogo => (
			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
			            ))
			            : <React.Fragment></React.Fragment>
			          }
			        </select>

						let material_1=
						  <select className="select-css" value={this.props.ficha_activo[0].id_material_1} onChange={(event)=>this.hC_Campo14(event)}>
			          {(ficha_activo_catalogo.length !== 0) ?
			            ficha_activo_catalogo.filter(item => item.id_tipo === '4').map(catalogo => (
			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
			            ))
			            : <React.Fragment></React.Fragment>
			          }
			        </select>

						let material_2=
						  <select className="select-css" value={this.props.ficha_activo[0].id_material_2} onChange={(event)=>this.hC_Campo15(event)}>
			          {(ficha_activo_catalogo.length !== 0) ?
			            ficha_activo_catalogo.filter(item => item.id_tipo === '5').map(catalogo => (
			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
			            ))
			            : <React.Fragment></React.Fragment>
			          }
			        </select>

              let estado=
                <select className="select-css" value={this.props.ficha_activo[0].id_estado} onChange={(event)=>this.hC_Campo16(event)}>
                  {(ficha_activo_catalogo.length !== 0) ?
                    ficha_activo_catalogo.filter(item => item.id_tipo === '6').map(catalogo => (
                      <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
                    ))
                    : <React.Fragment></React.Fragment>
                  }
                </select>

                let operativo=
    						  <select className="select-css" value={this.props.ficha_activo[0].id_operativo} onChange={(event)=>this.hC_Campo17(event)}>
    			          {(ficha_activo_catalogo.length !== 0) ?
    			            ficha_activo_catalogo.filter(item => item.id_tipo === '7').map(catalogo => (
    			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
    			            ))
    			            : <React.Fragment></React.Fragment>
    			          }
    			        </select>

                  let ubicacion_1=
      						  <select className="select-css" value={this.props.ficha_activo[0].id_ubicacion_1} onChange={(event)=>this.hC_Campo18(event)}>
      			          {(ficha_activo_catalogo.length !== 0) ?
      			            ficha_activo_catalogo.filter(item => item.id_tipo === '8').map(catalogo => (
      			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
      			            ))
      			            : <React.Fragment></React.Fragment>
      			          }
      			        </select>

                  let ubicacion_2=
      						  <select className="select-css" value={this.props.ficha_activo[0].id_ubicacion_2} onChange={(event)=>this.hC_Campo19(event)}>
      			          {(ficha_activo_catalogo.length !== 0) ?
      			            ficha_activo_catalogo.filter(item => item.id_tipo === '9').map(catalogo => (
      			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
      			            ))
      			            : <React.Fragment></React.Fragment>
      			          }
      			        </select>

                    let ubicacion_3=
        						  <select className="select-css" value={this.props.ficha_activo[0].id_ubicacion_3} onChange={(event)=>this.hC_Campo20(event)}>
        			          {(ficha_activo_catalogo.length !== 0) ?
        			            ficha_activo_catalogo.filter(item => item.id_tipo === '10').map(catalogo => (
        			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
        			            ))
        			            : <React.Fragment></React.Fragment>
        			          }
        			        </select>

											let responsable=
	        						  <select className="select-css" value={this.props.ficha_activo[0].id_responsable} onChange={(event)=>this.hC_Campo21(event)}>
													 <option value="0">-</option>
													{(ficha_activo_catalogo.length !== 0) ?
	        			            ficha_activo_catalogo.filter(item => item.id_tipo === '11').map(catalogo => (
	        			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
	        			            ))
	        			            : <React.Fragment></React.Fragment>
	        			          }
	        			        </select>

										 let control=
		        						  <select className="select-css" value={this.props.ficha_activo[0].id_control} onChange={(event)=>this.hC_Campo22(event)}>
														 <option value="0">-</option>
														{(ficha_activo_catalogo.length !== 0) ?
		        			            ficha_activo_catalogo.filter(item => item.id_tipo === '12').map(catalogo => (
		        			              <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
		        			            ))
		        			            : <React.Fragment></React.Fragment>
		        			          }
		        			        </select>

/*<tr>
<th>Cuenta Activo</th>
 <th>-</th>
</tr>
<tr>
<th>Cuenta Gasto Depreciación</th>
 <th>-</th>
</tr>*/
				const foto_1  = "http://neola.ec/activos/getFile.php?id="+this.props.id_activo;
				//const foto_2  = "http://neola.ec/activos/getFile.php?id="+this.props.id_activo+"&cont=_2";
				//const foto_3  = "http://neola.ec/activos/getFile.php?id="+this.props.id_activo+"&cont=_3";

				ficha_activo =<React.Fragment>
				<table style={{'text-align': 'left'}}>
				<thead>
					<tr>
						<th>Campo</th>
						<th>Valor</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th>Código</th>
						<th><input className="input_txt" type="text" name="codigo" value={this.props.ficha_activo[0].codigo} onChange={(event)=>this.hC_Campo1(event)} placeholder="Código" /></th>
					 </tr>
					 <tr>
						<th>Cuenta</th>
						<th><input style={{width: "400px"}}  className="input_txt" type="text" name="cuenta_contable" value={this.props.ficha_activo[0].cuenta_contable} onChange={(event)=>this.hC_Campo2(event)} placeholder="Cuenta Contable" /></th>
					 </tr>
					 <tr>
					 <th>Descripción</th>
						<th><input style={{width: "400px"}}  className="input_txt" type="text" name="descripcion" value={this.props.ficha_activo[0].descripcion} onChange={(event)=>this.hC_Campo3(event)} placeholder="Descripción" /></th>
					 </tr>
					 <tr>
					 <th>Características</th>
						<th><input style={{width: "400px"}} className="input_txt" type="text" name="caracteristicas" value={this.props.ficha_activo[0].caracteristicas} onChange={(event)=>this.hC_Campo4(event)} placeholder="Características" /></th>
					 </tr>
					 <tr>
					 <th>Marca</th>
						<th>{marca}</th>
					 </tr>
					 <tr>
					 <th>Modelo</th>
						<th>{modelo}</th>
					 </tr>
					 <tr>
					 <th>Serie</th>
						<th><input className="input_txt" type="text" name="serie" value={this.props.ficha_activo[0].serie} onChange={(event)=>this.hC_Campo5(event)} placeholder="Serie" /></th>
					 </tr>
					 <tr>
					 <th>Largo</th>
						<th><input className="input_txt" type="text" name="largo" value={this.props.ficha_activo[0].largo} onChange={(event)=>this.hC_Campo6(event)} placeholder="Largo" /></th>
					 </tr>
					 <tr>
					 <th>Ancho</th>
						<th><input className="input_txt" type="text" name="ancho" value={this.props.ficha_activo[0].ancho} onChange={(event)=>this.hC_Campo7(event)} placeholder="Ancho" /></th>
					 </tr>
					 <tr>
					 <th>Alto</th>
						<th><input className="input_txt" type="text" name="alto" value={this.props.ficha_activo[0].alto} onChange={(event)=>this.hC_Campo8(event)} placeholder="Alto" /></th>
					 </tr>
					 <tr>
					 <th>Diámetro</th>
						<th><input className="input_txt" type="text" name="diametro" value={this.props.ficha_activo[0].diametro} onChange={(event)=>this.hC_Campo9(event)} placeholder="Diámetro" /></th>
					 </tr>
					 <tr>
					 <th>Color</th>
						<th>{color}</th>
					 </tr>
					 <tr>
					 <th>Material 1</th>
						<th>{material_1}</th>
					 </tr>
					 <tr>
					 <th>Material 2</th>
						<th>{material_2}</th>
					 </tr>
					 <tr>
					 <th>Estado</th>
						<th>{estado}</th>
					 </tr>
					 <tr>
					 <th>Operativo</th>
						<th>{operativo}</th>
					 </tr>
					 <tr>
					 <th>Ubicación 1</th>
						<th>{ubicacion_1}</th>
					 </tr>
					 <tr>
					 <th>Ubicación 2</th>
						<th>{ubicacion_2}</th>
					 </tr>
					 <tr>
					 <th>Ubicación 3</th>
						<th>{ubicacion_3}</th>
					 </tr>
					 <tr>
					 <th>Costo Histórico</th>
						<th><input className="input_txt" type="text" name="valor" value={this.props.ficha_activo[0].valor} onChange={(event)=>this.hC_Campo10(event)} placeholder="Valor" /></th>
					 </tr>
           <tr>
					 <th>Activo de Control / Depreciable</th>
						<th>{control}</th>
					 </tr>
           <tr>
					 <th>Fecha Adquisición</th>
					 <th><DatePickerInput style={{width: "150px"}} locale='es' onChange={this.onChange} value={this.state.date} className='my-custom-datepicker-component' /></th>

					 </tr>
           <tr>
					 <th>Vida Útil</th>
						<th><input className="input_txt" type="text" name="vida_util" value={this.props.ficha_activo[0].vida_util} onChange={(event)=>this.hC_Campo23(event)} placeholder="Vida Útil" /></th>
					 </tr>
           <tr>
           <th>Número Factura </th>
           <th><input className="input_txt" type="text" name="numero_factura" value={this.props.ficha_activo[0].numero_factura} onChange={(event)=>this.hC_Campo24(event)} placeholder="Número Factura" /></th>
          </tr>
          <tr>
          <th>Proveedor</th>
           <th><input className="input_txt" type="text" name="proveedor" value={this.props.ficha_activo[0].proveedor} onChange={(event)=>this.hC_Campo25(event)} placeholder="Proveedor" /></th>
          </tr>
          <tr>
          <th>Documento de Registro</th>
           <th><input className="input_txt" type="text" name="numero_documento" value={this.props.ficha_activo[0].numero_documento} onChange={(event)=>this.hC_Campo26(event)} placeholder="Número de Diario/Egreso" /></th>
          </tr>
					<tr>
          <th>Responsable</th>
           <th>{responsable}</th>
          </tr>
					 <tr>
					 <td colSpan='2'>
					 <img src={foto_1} alt="logo" className="my-loader" />
					 </td>
					 </tr>
				</tbody>
			 </table>
				</React.Fragment>
			}

			//console.log(ficha_activo)
      return (
				 <React.Fragment>
         {ficha_activo}
				 </React.Fragment>
      )
  }
}
export default getFichaActivo;

import React, { Component } from 'react';
import AccordionItem from './../Componentes/AccordionItem';
class Accordion extends Component {
  getOpcion = (id_opcion,opcion) =>{
    this.props.getOpcion(id_opcion,opcion);
  }
  render () {
    const data = this.props.data;
    return (
      <div {...{ className: 'wrapper' }}>
        <ul {...{ className: 'accordion-list' }}>
          {data.map((data, key) => {
            return (
              <li {...{ className: 'accordion-list__item', key }}>
                <AccordionItem getOpcion={this.getOpcion} {...data} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
export default Accordion;

import React, { Component } from 'react';
import StickyHeadTable from './../Componentes/StickyHeadTable';
import GetFichaActivo from './../Componentes/getFichaActivo';
import { Element } from 'react-scroll';
import { scroller } from 'react-scroll';
import 'moment/locale/es.js';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import './../css/getBusqueda.css';
class getCrearActivo extends Component {

  constructor(){
    super();
    this.state = {
      ficha_activo_catalogo: [],
      ficha_activo:[ {ancho: "",
      caracteristicas: "",
      codigo: "",
      color: "",
      cuenta_contable: "",
      descripcion: "",
      diametro: "",
      id_color: "",
      id_item_activo_ficha: "",
      id_marca: "",
      id_material_1: "",
      id_material_2: "",
      id_modelo: "",
      largo: "",
      marca: "",
      material_1: "",
      material_2: "",
      modelo: "",
      operativo: "",
      serie: "",
      ubicacion_1:"",
      ubicacion_2: "",
      ubicacion_3: "",
      valor: ""}],
      date : ''
    }

  }
  getActualizarFicha =(valor)=>{
    let ficha_activo_catalogo = this.state.ficha_activo_catalogo;
    this.setState({
      ficha_activo_catalogo
    });
  }
  onChange = (jsDate, dateString) => {
    // ...
  }

  consultarFiltosAPI = () => {
    const url= this.props.url_global + "webservices/getCatalogosActivos/";
     //console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ ficha_activo_catalogo: data })
        }
      })
      .catch(console.log)

  }

  render(){
      return (
        <div>
          <div>
            <Element>
            <GetFichaActivo getActualizarFicha={this.getActualizarFicha} ficha_activo_catalogo={this.state.ficha_activo_catalogo} ficha_activo={this.state.ficha_activo} id_activo={this.state.id_activo} />
            </Element>
          </div>
        </div>
      )
  }
  componentDidMount (){
    this.consultarFiltosAPI();
  }
}
export default getCrearActivo;

import React, { Component } from 'react';
import StickyHeadTable from './../Componentes/StickyHeadTable';
import GetFichaActivo from './../Componentes/getFichaActivo';
import { Element } from 'react-scroll';
import { scroller } from 'react-scroll';
import 'moment/locale/es.js';
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import './../css/getBusqueda.css';
import Moment from 'moment';
class getEntrega extends Component {
  constructor(){
    super();
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let separator = "-";
    //console.log(Moment(newDate).format('YYYY-MM-DD'))
    //console.log(`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`)
    //const hoy = new Date();
    this.state = {
      rows_actual : [],
      rows_entrega : [],
      rows : [],
      ficha_activo :[],
      ficha_activo_catalogo: [],
      id_estado : 0,
      id_operativo : 0,
      id_ubicacion_1 : 0,
      id_ubicacion_2 : 0,
      id_ubicacion_3 : 0,
      date : Moment(newDate).format('YYYY-MM-DD'),
      codigo :'',
      id_responsable :0
    }
//`${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }
hC_CampoRs = (event) => {
  this.setState({id_responsable : event.target.value},  () =>
    {
     this.getBusqueda_();
    });
}
  handleChange = (event) => {
		this.setState({codigo : event.target.value},  () =>
      {
       this.getBusqueda_();
      });
  }

  getBusqueda_ = () =>{
    let rows_actual = this.state.rows_actual;
    let codigo = this.state.codigo;
    //console.log(codigo.substr(0, 2))
    if(codigo!==''){
      rows_actual = rows_actual.filter(item => item.codigo.substr(0, codigo.length) === codigo);
    }
    this.setState({
      rows : rows_actual
    })
    //const list_array_nota = this.state.array_nota.filter(item => item.secuencial !== secuencial);

  }



  consultarAPI = () => {


    const url= this.props.url_global + "webservices/getListaActivos/";
    // console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ rows_actual: data,rows: data })
        }
      })
      .catch(console.log)
  }
  consultarFichaAPI = (id) => {

    //alert(id)
    this.setState({
      ficha_activo :[]
    });
    const url= this.props.url_global + "webservices/getFichaActivo/"+id;
     //console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ ficha_activo: data,
          id_activo:id })
          scroller.scrollTo("lbl_ficha_activa_scroll");
          this.props.setLoadingClose();
        }
      })
      .catch(console.log)
      this.props.setLoading();
  }
  consultarFiltosAPI = () => {
    const url= this.props.url_global + "webservices/getCatalogosActivos/";
     //console.log(url);
      fetch(url)
      .then(res => res.json())
      .then((data) => {
        if(data[0].status!=='0'){
          this.setState({ ficha_activo_catalogo: data })
        }
      })
      .catch(console.log)

  }
  cancelar = () =>{
    this.setState({
      rows_entrega : []
    })
  }
  encerar  = () =>{
    this.consultarAPI();
    this.consultarFiltosAPI();
    this.cancelar();
  }
  crear_documento = (opcion) =>{

    if(opcion===1){
        const rows_entrega = this.state.rows_entrega;
        const id_responsable = this.state.id_responsable
        const date = this.state.date;
        let id_activo_seleccion ="";
        if(rows_entrega.length === 0) return null;
        if(id_responsable === 0) return null;
        const url= this.props.url_global + "webservices/getCrearCatalogoActaEntrega";
        rows_entrega.forEach(cp => {
          if(id_activo_seleccion===''){
            id_activo_seleccion= cp.rows_actual[0].id_activo ;
          }else{
            id_activo_seleccion=id_activo_seleccion+","+cp.rows_actual[0].id_activo ;
          }
        })
        console.log(date)
        var obj = { id_activo_seleccion , date , id_responsable};

        fetch(url, {
          method: 'POST', // or 'PUT'
          body: JSON.stringify(obj), // data can be `string` or {object}!
          headers:{
            'Accept':'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(res => res.json())
        .then((data) => {
          if(data[0].status==="ok"){
            window.open(this.props.url_global + "webservices/getPDFActa/1/"+date+"/"+id_responsable, "_blank")
            this.setState({
              rows_entrega :[]
            })
            this.encerar();
            this.props.setLoadingClose();
          }
        })
        .catch(console.log)
        this.props.setLoading();
        /*this.setState({
          loading:true
        })*/
    }
  }


  getTableSeleccion = () =>{
    const ficha_activo_catalogo = this.state.ficha_activo_catalogo;
    let responsable=
      <select className="select-css" style={{width: "250px", display: "inline"}} value={this.state.id_responsable} onChange={(event)=>this.hC_CampoRs(event)}>
        <option value="0">Todos</option>
        {(ficha_activo_catalogo.length !== 0) ?
          ficha_activo_catalogo.filter(item => item.id_tipo === '11').map(catalogo => (
            <option key={catalogo.id_item_catalogo} value={catalogo.id_item_catalogo}>{catalogo.item_catalogo}</option>
          ))
          : <React.Fragment></React.Fragment>
        }
      </select>

    const rows_entrega = this.state.rows_entrega;
    //console.log(rows_entrega)
    if(rows_entrega.length === 0) return null;
    return (
      <React.Fragment>
        <table className="table_"><thead>
        <tr><th>Código</th><th>Detalle</th><th>Responsable</th><th>Opciones</th></tr>
        </thead>
        <tbody>
        <tr><th></th><th></th><th></th><th rowspan={rows_entrega.length+1}>
        {responsable}<br/>
        <button className="button_login" style={{width: "220px", display: "inline"}} onClick={ () => this.crear_documento(1)}>Crear Documento</button><br/>
        <button className="button_login" style={{width: "220px", display: "inline"}} onClick={ () => this.cancelar()}>Cancelar</button></th></tr>
        {rows_entrega.map(item => (
          <tr key={item.rows_actual[0].codigo} className="boton_item_subgrupo"><th>
          {item.rows_actual[0].codigo}</th><th>{item.rows_actual[0].detalle}</th><th>{item.rows_actual[0].responsable}</th></tr>
          ))}
          <br/>
          </tbody>

        </table>
      </React.Fragment>
    )
  }
  setOpenFicha =(id)=>{
    //console.log("dddd")
    let sw_continuar= 1;
    this.state.rows_entrega.forEach(cp => {
      if(cp.rows_actual[0].id_activo===id){
        sw_continuar = 0;

      }
    })

  if(sw_continuar===0){
    return null;
  }

    let rows_actual = this.state.rows_actual.filter(item => Number(item.id_activo) === Number(id));

    this.setState({
      rows_entrega: [ ...this.state.rows_entrega, {
        rows_actual
      }]});
  }
  getActualizarFicha =(valor)=>{

  }
  onChange = (jsDate, dateString) => {
    console.log(dateString)
    this.setState(
      {
        date: dateString
      }
    );
  }

  render(){
    const ficha_activo_catalogo = this.state.ficha_activo_catalogo;
      return (
        <div className="grid-container-busqueda">
          <div className="lbl_filtros">
             <table style={{'text-align': 'left','width':'100%'}}>
    				<tbody>
    					<tr>
               <th className="lbl_color">Código</th>
     						<th>
                <input className="input_txt" type="text" name="codigo" value={this.state.codigo} onChange={this.handleChange} placeholder="Código" /></th>
               </tr>
               <tr>
                <th className="lbl_color">Fecha de Entrega</th>
      						<th>
                 <DatePickerInput displayFormat='DD/MM/YYYY' returnFormat='YYYY-MM-DD' style={{width: "150px"}}
                 locale='es' onChange={this.onChange} value={this.state.date}  className='my-custom-datepicker-component' /></th>
                </tr>

               <tr>
                <th colspan="2">{this.getTableSeleccion()}</th>
              </tr>
             </tbody>
            </table>
          </div>
          <div className="lbl_detalle_tabla">
            Lista de Activos<br/>
            <StickyHeadTable setOpenFicha={this.setOpenFicha}  rows={this.state.rows} />
          </div>
          <div className="lbl_ficha_activa">
            <Element name="lbl_ficha_activa_scroll">
            <GetFichaActivo getActualizarFicha={this.getActualizarFicha} ficha_activo_catalogo={this.state.ficha_activo_catalogo} ficha_activo={this.state.ficha_activo} id_activo={this.state.id_activo} />
            </Element>
          </div>
        </div>
      )
  }
  componentDidMount (){
    this.consultarAPI();
    this.consultarFiltosAPI();
  }
}
export default getEntrega;

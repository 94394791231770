
import React, { Component } from 'react';

class getLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {usuario: '',clave: ''};
    this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {

     let nam = event.target.name;
     let val = event.target.value;
     this.setState({[nam]: val});
  }




  render() {
    const name = this.props.name;
    return (
      <div>
      <img className="img_logo" alt="Cargando ..." src={ require('../images/logo.png') } />
      <div>
      <label className="name_inicial">{name}</label>
      </div>
      <div className="login">

          <p>
            <input className="input_txt" type="text" name="usuario" value={this.state.usuario} onChange={this.handleChange} placeholder="Usuario o RUC" />
          </p>
          <p>
            <input className="input_txt" type="password" name="clave" value={this.state.clave} onChange={this.handleChange} placeholder="Clave" />
          </p>
          <p className="remember_me">
            <span className="lbl-sms">
              {this.props.sms_mensaje}
            </span>
          </p>
          <p className="submit">
            <button className="button_login" onClick={() => this.handlegetUser()}>
              Ingresar
            </button>
          </p>

      </div>


    </div>

    );
 /*
 <div className="login-help">
   <p>¿No recuerda su contraseña? <a href ={null}>Clic aquí para recuperarla</a>.</p>
 </div>*/
  }


  handlegetUser = () => {
    const usuario = this.state.usuario;
    const clave = this.state.clave;
    this.props.BuscarUsuario(usuario, clave);
  };
}
export default getLogin;
